import { Component, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-static-banner',
  standalone: true,
  templateUrl: './static-banner.component.html',
  styleUrls: ['./static-banner.component.css'],
  imports: [NgOptimizedImage],
})
export class StaticBannerComponent implements OnInit {
  assetsUrl = environment.assetsUrl;
  bannerUrl = this.assetsUrl + '/img/home/banner.png';

  constructor() {}

  ngOnInit() {}
}
